import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";

/**
 * Props for `DefaultSlice`.
 */
export type DefaultSliceProps = SliceComponentProps<Content.DefaultSliceSlice>;

/**
 * Component for "DefaultSlice" Slices.
 */
const DefaultSlice = ({ slice }: DefaultSliceProps): JSX.Element => (
    <div
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className="hidden"
    />
);

export default DefaultSlice;
